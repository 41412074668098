.resetmainDiv {
  background: #1d8de2;
  height: 100vh;
  padding: 3% 0;
}
.resetlogoDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}
.resetlogo {
  width: 50%;
}
.resetFormDiv {
  background: white;
  border-radius: 30px;
  width: 30%;
  margin: auto;
  padding: 3% 0;
  font-family: 'Outfit';
}
.resetTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 600;
  padding: 0px;
}
.restdescription {
  text-align: center;
  color: gray;
  font-size: 17px;
  font-weight: 600;
  padding: 5px 10% 5% 10%;
}
.resetInputDiv {
  padding: 10px 10px 10px 20px;
  width: 90%;
  margin: auto;
}
.resetInput {
  width: 100%;
  height: 55px;
  border: 1px solid rgb(167, 164, 164);
  border-radius: 100px;
  padding-left: 25px;
}
.resethidePassword {
  position: absolute;
  margin: -14px -43px;
  cursor: pointer;
}
.resetbtn {
  background: #1d8de2;
  width: 80%;
  margin: 13px 5px 10px 5px;
  color: white;
  border-radius: 100px;
  padding: 14px 26px;
  font-weight: 600;
}
.resetbtn:hover {
  background: #2287d4;
}
.resetlogo2 {
  position: absolute;
  bottom: 0;
  opacity: 0.3;
  width: 23%;
}

@media screen and (max-width: 680px) {
  .resetmainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .resetFormDiv {
    width: 90%;
  }
  .restdescription {
    font-size: 14px;
    padding: 5px 7% 5% 7%;
  }
  .resethidePassword {
    position: absolute;
    margin: -13px -35px;
  }
  .loginbtn {
    width: 100%;
    margin: 20px 0px;
  }
}
