.PrivateSwitchBase-root-1 {
  padding: 5px !important;
}
.MuiAutocomplete-inputRoot {
  border-radius: 40px !important;
}

.MuiAutocomplete-clearIndicator {
  padding: 0px !important;
}

.Menumaind1 {
  display: flex;
  justify-content: space-between;
  margin: 10px 15px 20px 15px;
  font-weight: 600;
  font-family: 'Outfit';
}
.clearallFilterbtn:hover {
  text-decoration: underline;
}
.Menumaind2 {
  font-weight: 600;
  margin: 10px 15px 10px 15px;
  font-family: 'Outfit';
}
.Menumaind3 {
  margin-left: 20px;
  font-size: 14px;
}
.filterbtndiv {
  text-align: center;
  margin: 0px 40px;
}

.filterClose {
  color: gray;
  border: 2px solid gray;
  font-weight: 600;
  padding: 8px 13px;
  margin: 10px 5px;
  border-radius: 20px;
}
.filterApply {
  background: #1d8de2;
  color: white;
  font-weight: 600;
  padding: 8px 13px;
  margin: 10px 5px;
  border: 2px solid #1d8de2;
  border-radius: 20px;
}
.filtercheckbox1 {
  padding: 0px;
}
.filtercheckboxtext {
  margin: 3px;
}

.filterSection {
  background: rgba(201, 199, 199, 0.5);
  padding: 20px;
  /* margin: 10px 0px; */
  font-family: 'Outfit';
  min-height: 100vh;
}
.searchHospital {
  width: 95%;
  margin: auto;
  padding: 0px 8px 10px 8px;
}
.electivlistDivMain {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  width: 92%;
  margin: auto;
}
.electivlistDiv {
  color: #1d8de2;
  width: 30%;
  font-weight: 600;
  font-size: 33px;
}
.electiveSection {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  margin: auto;
}
.electiveCard {
  background: #ffffff;
  width: 30%;
  margin: 20px;
  padding: 20px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  border: 2px solid white;
}
.electiveCard:hover {
  /* background: #f7f5f5; */
  border: 2px solid #1d8de2;
}
.menuActive2 {
  opacity: 0.5;
}
.headingDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heading1 {
  padding: 15px 5px;
  font-weight: 500;
}
.electivedesDiv {
  height: 173px;
}
.cardP1 {
  color: gray;
  font-size: 13px;
  padding: 5px 5px 15px 5px;
  font-weight: 500;
}
.btnDiv {
  display: flex;
  justify-content: space-around;
}
.logoDiv {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.electivePics {
  width: 90px;
  height: 90px;
  border-radius: 13px;
}
.appliedBtn {
  background: #1d8de2;
  border-radius: 10000px;
  color: white;
  font-weight: 500;
  padding: 4px 18px;
}
.electiveMenuBtn {
  border-radius: 10000px;
  color: white;
  font-weight: 500;
  padding: 4px 0px;
}
.EbtnLimit {
  background: rgb(255 0 0 / 50%);
  color: white;
  border-radius: 10000px;
  padding: 2px 13px;
  font-weight: 500;
}
.Ebtn {
  background: rgba(217, 217, 217, 0.5);
  color: gray;
  border-radius: 10000px;
  padding: 2px 13px;
  font-weight: 500;
}
.Ebtn:hover {
  background: rgba(201, 199, 199, 0.5);
}
.dateBtn {
  padding: 5px;
  color: #1d8de2;
  background: rgba(29, 141, 226, 0.05);
  border-radius: 10000px;
  padding: 5px 45px;
  font-weight: 500;
}
.dateBtn:hover {
  background: rgb(0 0 0 / 5%);
}

.singleElectiveContainer {
  width: 92%;
  margin: auto;
  padding: 4% 4%;
}

.singleElectiveHeading {
  display: flex;
  align-items: center;
}
.electivetitle {
  font-size: 27px;
  font-weight: 600;
  margin: 5px 10px 5px 20px;
}
.electivetitleHmail {
  font-size: 12px;
  color: gray;
  font-weight: 600;
  margin: 5px 10px 5px 25px;
}
.heding1 {
  color: #1d8de2;
  font-size: 23px;
  font-weight: 700;
  margin: 30px 5px 14px 7px;
}
.singleloc {
  display: flex;
  justify-content: space-between;
}
.loc1 {
  display: flex;
  font-weight: 600;
}
.datediv {
  font-weight: 600;
}
.singleP1 {
  font-size: 15px;
  font-weight: 500;
  color: gray;
  margin: 35px 0px;
}
.singlebtnDiv {
  border: 1px solid rgb(201, 191, 191);
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-weight: 600;
  padding: 12px 3%;
  border-radius: 10px;
}
.singlereservebtnDiv {
  display: flex;
  justify-content: right;
  padding: 14% 10% 1% 1%;
}

.singlebr {
  display: none;
}
.btndiv2 {
  display: none;
}
.filter1dashboard {
  font-weight: 600;
  display: flex;
  width: 35%;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;
}
.SortArrow1D {
  width: 4%;
  margin: 0px 11px 5px 11px;
}
.SortArrow2D {
  margin: 0px 11px 5px 21px;
}
.addbtnDiv {
  position: fixed;
  bottom: 50px;
  right: 50px;
  text-align: right;
  padding: 0px 20px;
}
.addbtn {
}
.addbtnimg {
}
.addelectivediv2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.noElectivesMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Outfit';
  margin-top: 8%;
}
.noElectivefound {
  width: 100%;
  text-align: center;
  padding: 15% 0;
  font-size: 30px;
  font-weight: 600;
  color: gray;
}
.createElectivediv {
  font-size: 30px;
  font-weight: 700;
  color: gray;
  opacity: 0.7;
  padding: 5px 0px;
}
.addnewElectivebtn {
  background: #1d8de2;

  margin: 5px 5px;
  color: white;
  border-radius: 100px;
  padding: 14px 26px;
  font-weight: 600;
}
.headingdiv1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2%;
}
.modifiedDiv {
  display: flex;
}
.editiconimg {
  margin: 0px 5px;
}
.editiconimg:hover {
  background-color: #e9eaec;
  border-radius: 50%;
}
.menuElectives {
  text-align: center;
  font-weight: 600;
  padding: 7px 5px;
}
.menuElectives:hover {
  background: #edeff0;
  border-radius: 15px;
}
.menutbtn {
  display: flex;
  align-items: center;
}
.menuicon {
  cursor: pointer;
  margin: 0px 12px;
}
.menuMainDiv {
  background: #f7f9fa;
  width: 9%;
  box-shadow: 0px 4px 20px rgba(8, 8, 8, 0.05);
  border-radius: 10px;
  padding: 0px 20px 5px 20px;
}
.maind1 {
  padding: 5px 10px;
}
.D1icon {
  margin: 0px 5px;
}
.linerloading1 {
  padding: 5% 0;
}
.noteDiv {
  background: #f9e9e4 5%;
  padding: 15px 20px;
  margin: 25px 5px;
  width: 60%;
  border-radius: 12px;
  display: flex;
  align-items: center;
}
.noteDiv:hover {
  text-decoration: underline;
}
.NoteImg {
  margin: 0px 10px;
}
.appliedDiv {
  text-align: left;
}

@media screen and (max-width: 680px) {
  .Ebtn {
    font-size: 12px;
  }
  .addbtnDiv {
    bottom: 32px;
    right: 20px;
  }
  .noteDiv {
    padding: 15px 0px;
    margin: 25px auto;
    width: 100%;

    display: flex;
    align-items: center;
  }
  .noteMsg {
    font-size: 13px;
  }
  .menuMainDiv {
    left: 50%;
    width: 45%;
  }

  .electiveSection {
    flex-direction: column;
    width: 100%;
  }
  .singleElectiveContainer {
    width: 95%;
    margin: auto;
    padding: 3% 3%;
  }
  .electiveCard {
    background: #ffffff;
    width: 100%;
    margin: 20px auto;
    padding: 25px 20px;
  }
  .menuActive2 {
    opacity: 0.7;
  }
  .cardP1 {
    font-size: 11px;
  }
  .dateBtn {
    padding: 5px 25px;
  }

  .singleElectiveHeading {
    flex-direction: column;
    align-items: baseline;
  }
  .electivetitle {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 10px 0px 0px;
  }
  .electivetitleHmail {
    font-size: 12px;

    margin: 5px 10px 5px 0px;
  }
  .heding1 {
    margin: 20px 5px 20px 5px;
    font-size: 21px;
  }
  .datediv {
    margin: 6px 0px 0px 9px;
  }

  .singleloc {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .singleP1 {
    font-size: 15px;
    font-weight: 500;
    color: gray;
    margin: 25px 0px 20px 0px;
  }
  .singlebr {
    display: inline;
  }
  .singlebtnDiv {
    flex-direction: column;
    align-items: baseline;
  }
  .btndiv2 {
    display: inline;
    width: 100%;
    height: 1px;
    background-color: rgb(214, 207, 207);
    margin: 5px;
  }
  .singlereservebtnDiv {
    justify-content: center;
    padding: 19% 0% 0% 0%;
  }

  .filter1dashboard {
    width: 100%;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  .filtercheckbox1 {
    width: 90%;
    margin: auto;
    padding: 5px 5px 5px 5px;
  }
  .electivlistDivMain {
    display: block;
    width: 99%;
    margin: auto;
  }
  .electivlistDiv {
    width: 50%;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    margin: auto;
  }
  .desSpan {
    font-size: 19px;
    color: #1d8de2;
    font-weight: 600;
  }
}
