.applicant1 {
  font-size: 35px;
  font-weight: 600;
  padding: 10px 10px;
  width: 86%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.singleelectivelimit {
  font-size: 15px;
}
.applicantMaindiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  width: 85%;
  margin: auto;
}
.applicantDiv {
  border: 1px solid #1d8de2;
  border-radius: 12px;
  padding: 18px 30px;
  width: 21%;
  margin: 10px;
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}
.applicantName1 {
  font-weight: 600;
  font-size: 20px;
}
.applicantemail1 {
  color: gray;
}
.singleReservebtn {
  background: #1d8de2;
  border-radius: 10000px;
  color: white;
  font-weight: 500;
  padding: 8px 9%;
}
.AllreadyReservebtn {
  background: #848585;
  border-radius: 10000px;
  color: white;
  font-weight: 500;
  padding: 8px 9%;
}
.NoApplied {
  color: gray;
  width: 100%;
  text-align: center;
  padding: 10% 0%;
  font-size: 29px;
  font-weight: 600;
}
.csvbtn1 {
  background: #1d8de2;
  border-radius: 10000px;
  color: white;
  font-weight: 500;
  padding: 10px 20px;
  margin-right: 20px;
}
.csvbtn1:hover {
  background: #217ec6;
}
.customemenu1 {
  display: flex;

  padding: 9px;
  background: #ebe8e8;
  border-radius: 5px;
}
.customemenu1:hover {
  background: #e8e6e6;
}
.searchInputDiv {
  padding: 10px 10px 10px 20px;
}
.searchInput {
  width: 100%;
  height: 55px;
  border: 1px solid rgb(167, 164, 164);
  border-radius: 100px;
  padding: 2px 12px;
}

@media screen and (max-width: 680px) {
  .searchInputDiv {
    padding: 4px 0;
  }
  .searchInput {
    width: 100%;
  }
  .customemenu1 {
    display: flex;
    position: absolute;
    left: 21%;
    top: 63%;
    padding: 9px;
    background: #ebe8e8;
    border-radius: 5px;
  }
  .applicantMaindiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .singleelectivelimit {
    font-size: 14px;
    padding-right: 3%;
  }
  .applicant1 {
    font-size: 24px;
    padding-left: 6.5%;
    width: 100%;
  }
  .singleReservebtn {
    width: 93%;
    padding: 11px 9%;
  }
  .AllreadyReservebtn {
    width: 93%;
    padding: 11px 9%;
  }
  .applicantDiv {
    width: auto;
  }
}
