.HospitalprofileHeader {
  width: 100%;
  margin: auto;
  background: rgba(201, 199, 199, 0.5);
}
.HospitalprofileHeaderRow1 {
  width: 87%;
  margin: auto;
  padding: 2% 0%;
}

.name1 {
  font-size: 30px;
  font-weight: 600;
  color: #1d8de2;
}
.hospitalProfileName {
  font-weight: 600;
  font-size: 30px;
}
.applied1 {
  font-size: 30px;
  font-weight: 600;
}
.Hosapplieddiv {
  display: flex;
  justify-content: space-between;
  width: 92%;
  margin: auto;
}
.divline1 {
  width: 1px;
  height: 25px;
  padding: 1px 0px 1px 0px;
  /* background-color: rgb(184, 177, 177); */
}
.filter1 {
  font-weight: 600;
  display: flex;
  width: 25%;
}
.SortArrow1 {
  width: 5%;
  margin: 0px 7px 16px 5px;
}
.SortArrow2 {
  width: 5%;
  margin: 0px 7px 16px 5px;
}
.n1 {
  padding: 0px 6px 0px 15px;
}
.filterSection1 {
  min-height: 100vh;
  padding: 20px;
}

.hospitalcol2 {
  width: 95%;
  margin: auto;
}
.profileMaindiv {
  width: 35%;
  margin: 4% auto;
  background: #f9f9f9;
  border-radius: 25px;
  padding: 25px 25px;
}
.hospitalprofilepic {
  outline: 5px solid rgba(29, 141, 226, 1);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin: auto;
}
.hospitalProfileloginInputDiv {
  padding: 4px 10px 0px 20px;
  width: 85%;
  margin: auto;
}
.HPimgprofiile {
  padding: 10px 10px 10px 20px;
  width: 35%;
  margin: auto;
  text-align: end;
}
.Hprofileloginp {
  padding: 5px;
  font-weight: 600;
}
.HProfileloginInput {
  width: 100%;
  height: 55px;
  border: 2px solid black;
  border-radius: 100px;
  padding-left: 25px;
}
.HprofilecreateelectivebtnDiv {
  text-align: center;
  margin: 20px 0px 10% 0px;
}
.changePasswordbtn {
  background: #eaf2f8;
  width: 75%;
  margin: 10px auto;
  color: rgba(29, 141, 226, 1);
  border-radius: 100px;
  padding: 14px 26px;
  font-weight: 500;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.changePasswordbtn:hover {
  background: #dce9f4;
}
.HProfilecreateelectivebtn {
  background: rgba(29, 141, 226, 1);
  width: 75%;
  margin: 10px auto;
  color: white;
  border-radius: 100px;
  padding: 14px 26px;
  font-weight: 600;
  text-align: center;
}
.HProfilecreateelectivebtn:hover {
  background: rgb(25, 108, 171);
}
.changepasswordh1 {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  padding: 10px 0px;
}
.changepasswordP1 {
  text-align: center;
  color: gray;
  font-weight: 600;
  padding: 0 8%;
  font-size: 16px;
}
.noimg {
  width: 100%;
}
.hospitalpasswordicon {
  position: relative;
  left: 380px;
  bottom: 35px;
}

@media screen and (max-width: 680px) {
  .profileMaindiv {
    width: 95%;
    margin: 2% auto;
    padding: 25px 0px;
  }
  .changepasswordP1 {
    font-size: 10px;
  }
  .applied1 {
    font-size: 15px;
  }
  .filter1 {
    width: 60%;
    justify-content: right;
  }
  .SortArrow1 {
    width: 5%;
    margin: 0px 7px 1px 5px;
  }
  .SortArrow2 {
    width: 5%;
    margin: 0px 7px 1px 5px;
  }
  .hospitalprofilepic {
    width: 70px;
    height: 70px;
    margin-top: 3%;
  }
  .hospitalProfileName {
    font-size: 15px;
  }
  .hospitalProfileloginInputDiv {
    padding: 4px 0;
  }
  .HProfileloginInput {
    width: 100%;
  }
  .changepasswordh1 {
    font-size: 22px;
  }
  .changePasswordbtn {
    margin: 15px auto 10px auto;
  }
  .hospitalpasswordicon {
    position: relative;
    left: 250px;
    bottom: 35px;
  }
}
