.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInput-underline:after {
  border-bottom: none !important;
}
.addelectivesh1 {
  font-size: 30px;
  font-weight: 700;
  padding: 10% 30px 0px 30px;
}
.addelectivesh2 {
  font-size: 15px;
  font-weight: 600;
  opacity: 0.5;
  padding: 0px 20px 10px 30px;
}
.addelectivep {
  padding: 5px;
}
.electiveInputDiv {
  padding: 10px 10px 10px 20px;
}
.addelectiveInput {
  width: 95%;
  height: 55px;
  border: 1px solid rgb(167, 164, 164);
  border-radius: 100px;
  padding-left: 25px;
}
.createelectivebtnDiv {
  text-align: center;
  margin: 20px 0px 10% 0px;
}
.createelectivebtn {
  background: #1d8de2;
  width: 90%;
  margin: 5px 5px 10px 5px;
  color: white;
  border-radius: 100px;
  padding: 14px 26px;
  font-weight: 600;
}
.createelectivebtn:hover {
  background: #1879c3;
}
.checkboxtext {
  margin-top: 5px;
}
.checkboxtext:hover {
  text-decoration: underline;
}
.deleteelectivesh1 {
  font-size: 25px;
  font-weight: 500;
  padding: 7% 30px 0px 30px;
  text-align: center;
}
.deleteelectivesh2 {
  font-size: 20px;
  font-weight: 500;
  opacity: 0.5;
  padding: 0px 20px 10px 30px;
  text-align: center;
}
.deleteelectivebtnDiv {
  text-align: center;
  margin: 20px auto;
  text-align: center;
  width: 95%;
}
.logodiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 0%;
}
.logodivExtend {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12% 0% 0% 0%;
}
.deletecencelbtn {
  background: white;
  margin: 5px 5px 10px 5px;
  color: white;
  border-radius: 100px;
  padding: 13px 40px;
  font-weight: 600;
  color: black;
  border: 2px solid black;
}
.deletecencelbtn:hover {
  background: #f4f7f9;
}
.deleteelectivebtn {
  background: #ff5151;
  margin: 5px 5px 10px 5px;
  color: white;
  border-radius: 100px;
  padding: 16px 40px;
  font-weight: 600;
}
.deleteelectivebtn:hover {
  background: #d94646;
}
.durationMain1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;
  width: 80%;
  margin: auto;
  background: #fafafa;
  border-radius: 100px;
}
.duration1 {
  text-align: end;
  color: #919b9b;
  font-weight: 600;
  width: 40%;
  font-size: 18px;
}
.duration2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
}
.Dline {
  width: 1px;
  height: 30px;
  background: gray;
}
.minusbtn {
  color: gray;
  font-size: 26px;
}
.plusbtn {
  color: #1d8de2;
  font-size: 26px;
}
.weeksDiv {
  font-weight: 500;
  font-size: 18px;
}
@media screen and (max-width: 680px) {
  .addelectivesh1 {
    font-size: 23px;
  }
  .addelectivesh2 {
    font-size: 10px;
  }
  .checkboxtext {
    font-size: 14px;
  }
  .addelectivep {
    padding: 10px;
  }
  .addelectiveInput {
    width: 100%;
  }
  .electiveInputDiv {
    width: 90%;
    margin: auto;
    padding: 5px 5px 5px 5px;
  }
}
