.LoginImg {
  height: 100vh;
}

.LoginImgMobile {
  display: none;
}

.logincol {
  display: flex;
  align-items: center;
  font-family: 'outfit';
}

.loginrow1 {
  width: 50%;
  position: relative;
}

.textDiv {
  position: absolute;
  bottom: 20%;
  width: 80%;
  padding: 0 12%;
  color: white;
}

.loginLogo {
  width: 35%;
  padding: 20px;
}

.loginH2 {
  font-size: 33px;
  font-weight: 700;
}

.lopinP2 {
  padding: 1% 0%;
}

.loginrow2 {
  width: 50%;
  padding: 0 6% 0 4%;
  font-family: 'outfit';
}

.loginDiv1 {
  padding: 6% 5%;
}

.loginp {
  padding: 5px;
}

.hidePassword {
  position: absolute;
  right: 25%;
  top: 18px;
  cursor: pointer;
}

.forgotdiv {
  width: 80%;
}

.forget {
  padding: 10px 0;
  font-weight: 600;
  width: 30%;
  margin-left: auto;
  color: #1d8de2;
}

.forget:hover {
  text-decoration: underline;
  cursor: pointer;
}

.loginH1 {
  font-size: 35px;
  font-weight: 600;
}

.loginP1 {
  font-size: 20px;
  font-weight: 600;
  color: gray;
  opacity: 0.7;
}

.loginInputDiv {
  padding: 10px 10px 10px 20px;
}

.loginInput {
  width: 80%;
  height: 55px;
  border: 1px solid rgb(167, 164, 164);
  border-radius: 100px;
  padding-left: 25px;
}

.loginbtnDiv {
  width: 95%;
  margin: auto;
}

.loginbtn {
  background: #1d8de2;
  width: 80%;
  margin: 5px 5px 10px 5px;
  color: white;
  border-radius: 100px;
  padding: 14px 26px;
  font-weight: 600;
}

.loginbtn:hover {
  background: #2287d4;
}

.p2 {
  font-size: 17px;
  color: gray;
}

.registerSpan {
  color: #1d8de2;
  font-weight: 600;
}

.p2div {
  text-align: center;
  width: 80%;
}

.registerSpan:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 680px) {
  .LoginImg {
    height: auto;
    display: none;
  }

  .LoginImgMobile {
    display: inline;
    width: 100%;
  }

  .logincol {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .loginrow1 {
    width: 100%;
  }

  .textDiv {
    position: absolute;
    bottom: 6%;
    width: 100%;
    padding: 0 5%;
    color: white;
  }

  .loginLogo {
    width: 32%;
    padding: 20px;
  }

  .loginH2 {
    font-size: 20px;
    font-weight: 600;
  }

  .loginH1 {
    font-size: 25px;
  }

  .loginP1 {
    font-size: 15px;
  }

  .lopinP2 {
    padding: 1% 0%;
  }

  .loginrow2 {
    width: 100%;
    padding: 0 6% 40px 4%;
    font-family: 'outfit';
  }

  .loginDiv1 {
    padding: 6% 2%;
  }

  .loginInputDiv {
    padding: 4px 0;
  }

  .loginInput {
    width: 100%;
  }

  .hidePassword {
    right: 5%;
  }

  .forgotdiv {
    width: 100%;
  }

  .forget {
    font-size: 14px;
    font-weight: 600;
    width: 35%;
    margin-left: auto;
    color: #1d8de2;
  }

  .loginbtnDiv {
    text-align: center;
  }

  .loginbtn {
    width: 100%;
    margin: 20px 0px;
  }

  .p2div {
    margin: auto;
    opacity: 0.7;
  }
}
