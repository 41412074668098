.profileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  background: linear-gradient(270deg, #1d8de2 0%, rgba(255, 255, 255, 0) 88.43%), #ffffff;
}
.Stdname1 {
  font-size: 45px;
  font-weight: 600;
  color: #1d8de2;
}
.email1 {
  font-weight: 600;
  font-size: 25px;
  margin-left: 10px;
}
.applied1 {
  font-size: 30px;
  font-weight: 600;
}
.applieddiv {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
}
.divline1 {
  width: 1px;
  height: 25px;
  padding: 1px 0px 1px 0px;
  background-color: rgb(184, 177, 177);
}
.filter1 {
  font-weight: 600;
  display: flex;
  width: 25%;
}
.SortArrow1std {
  width: 5%;
  margin: 0px 7px 16px 5px;
}
.SortArrow2std {
  width: 5%;
  margin: 0px 7px 16px 5px;
}
.n1 {
  padding: 0px 6px 0px 15px;
}
.filterSection1 {
  background: rgba(201, 199, 199, 0.5);
  padding: 20px;
}
.profileHeaderRow1 {
  width: 71%;
  margin: auto;
}
.editProfileBtn {
  color: #1d8de2;
  background: white;
  border-radius: 50px;
  padding: 15px 20px;
  font-weight: 600;
  position: relative;
  bottom: 120px;
  left: 0%;
  cursor: pointer;
}

@media screen and (max-width: 680px) {
  .Stdname1 {
    font-size: 25px;
  }
  .email1 {
    font-size: 15px;
  }
  .applied1 {
    font-size: 15px;
  }
  .filter1 {
    width: 60%;
    justify-content: right;
  }
  .SortArrow1std {
    width: auto;
    margin: 0px 7px 1px 5px;
  }
  .SortArrow2std {
    width: auto;
    margin: 0px 7px 1px 5px;
  }
  .profileHeaderRow1 {
    margin: auto 13px;
  }
  .editProfileBtn {
    color: #1d8de2;
    background: white;
    border-radius: 50px;
    padding: 15px 20px;
    font-weight: 600;
    position: relative;
    bottom: 74px;
    left: 20%;
  }
}
