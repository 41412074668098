.forgotpasswordTitle {
  color: #1d8de2;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  padding-top: 10%;
}
.forgotpasswordDesc {
  text-align: center;
  color: gray;
}

.forGotInput {
  width: 100%;
  height: 55px;
  border: 1px solid rgb(167, 164, 164);
  border-radius: 100px;
  padding-left: 25px;
}
.forGotInputDiv {
  padding: 10px 10px 10px 20px;
  width: 90%;
  margin: auto;
}
.forgotbtn {
  background: #1d8de2;
  width: 80%;
  margin: 13px 5px 40px 5px;
  color: white;
  border-radius: 100px;
  padding: 14px 26px;
  font-weight: 600;
}
.forgotbtn:hover {
  background: #2287d4;
}
