.MuiInput-underline:before {
  border-bottom: none !important;
}
.MuiInput-underline:after {
  border-bottom: none !important;
}
.addelectivesh1 {
  font-size: 30px;
  font-weight: 700;
  padding: 10% 30px 0px 30px;
}
.addelectivesh2 {
  font-size: 15px;
  font-weight: 600;
  opacity: 0.5;
  padding: 0px 20px 10px 30px;
}
.addelectivep {
  padding: 5px;
}
#editElectiveDate {
  color: rgba(145, 155, 155, 1);
}
.electiveInputDiv {
  padding: 10px 10px 10px 20px;
}
.electiveLimitInputDiv {
  padding: 0px 10px 10px 20px;
}
.addelectiveInput {
  width: 95%;
  height: 55px;
  border: 1px solid rgb(167, 164, 164);
  border-radius: 100px;
  padding-left: 25px;
}
#editElectiveDate {
  color: rgba(145, 155, 155, 1) !important;
}
.createelectivebtnDiv {
  text-align: center;
  margin: 20px 0px 10% 0px;
}
.createelectivebtn {
  background: #1d8de2;
  width: 90%;
  margin: 5px 5px 10px 5px;
  color: white;
  border-radius: 100px;
  padding: 14px 26px;
  font-weight: 600;
}
.createelectivebtn:hover {
  background: #1879c3;
}
.checkboxtext {
  margin-top: 5px;
}
.deleteelectivesh1 {
  font-size: 25px;
  font-weight: 700;
  padding: 7% 30px 0px 30px;
  text-align: center;
}
.deleteelectivesh2 {
  font-size: 20px;
  font-weight: 500;
  opacity: 0.5;
  padding: 0px 20px 10px 30px;
  text-align: center;
}
.deleteelectivebtnDiv {
  text-align: center;
  margin: 20px auto;
  text-align: center;
  width: 95%;
}
.logodiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2% 0%;
}
.deletecencelbtn {
  background: white;
  margin: 5px 5px 10px 5px;
  color: white;
  border-radius: 100px;
  padding: 13px 40px;
  font-weight: 600;
  color: black;
  border: 2px solid black;
}
.deletecencelbtn:hover {
  background: #f4f7f9;
}
.deleteelectivebtn {
  background: #ff5151;
  margin: 5px 5px 10px 5px;
  color: white;
  border-radius: 100px;
  padding: 16px 40px;
  font-weight: 600;
}
.deleteelectivebtn:hover {
  background: #d94646;
}
.activatebtn {
  background: #1d8de2;
  margin: 5px 5px 10px 5px;
  color: white;
  border-radius: 100px;
  padding: 16px 40px;
  font-weight: 600;
}
.activatebtn:hover {
  background: #197fcd;
}
.extendelectivebtn {
  background: #1d8de2;
  margin: 5px 5px 10px 5px;
  color: white;
  border-radius: 100px;
  padding: 16px 70px;
  font-weight: 600;
}
.extendelectivebtn:hover {
  background: #2284cf;
}
.Ename {
  text-align: center;
  color: #1d8de2;
  padding: 10px 0px;
  font-size: 22px;
  font-weight: 600;
}
@media screen and (max-width: 680px) {
  .addelectivep {
    padding: 10px;
  }
  .addelectiveInput {
    width: 100%;
  }
  .electiveInputDiv {
    width: 90%;
    margin: auto;
    padding: 5px 5px 5px 5px;
  }
  .electiveLimitInputDiv {
    width: 90%;
    margin: auto;
    padding: 0px 5px 5px 5px;
  }
}
